// @src imports
import { LEGACY_SITE_URL } from 'src/app/api'
import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import isFunction from 'lodash/isFunction'
import { Icon, ProfileAvatar, SuspenseBoundary, Transition } from 'src/chrome'
import { IconTypeOrIconString } from 'src/app/types'
import { compact } from 'src/helpers'
import { OrdersRoute } from 'src/orders/routes/OrdersRoute'
import {
  useAccount,
  useActions,
  useRoute,
  useSelector,
  useState,
} from 'src/hooks'

import styles from './bottomNav.module.scss'
import { InterpolationWithTheme } from '@emotion/core'
import { ConfirmDialog, Drawer, Text } from '@sendoutcards/quantum-design-ui'
import {
  useNotificationCount,
  useReceivedPendingMembershipInvites,
} from 'src/react_query'
import { OsanoCookieSVG } from '../SideBar/OsanoCookieSVG'

export const RELATIONSHIPS_ITEM = 'RELATIONSHIPS_ITEM'
const CAMPAIGNS_ITEM = 'CAMPAIGNS_ITEM'
export const CATALOG_ITEM = 'CATALOG_ITEM'
const GIFTS_ITEM = 'GIFTS_ITEM'
const OPEN_HELP_ITEM = 'OPEN_HELP_ITEM'
const CLOSE_HELP_ITEM = 'CLOSE_HELP_ITEM'
const PRIVACY_ITEM = 'PRIVACY_ITEM'
const HELP_MENU_ITEM = 'HELP_MENU_ITEM'
const MESSAGE_ITEM = 'MESSAGE_ITEM'
const TIPS_ITEM = 'TIPS_ITEM'
const PRICING_ITEM = 'PRICING_ITEM'

type Item =
  | typeof RELATIONSHIPS_ITEM
  | typeof CAMPAIGNS_ITEM
  | typeof CATALOG_ITEM
  | typeof GIFTS_ITEM
  | typeof OPEN_HELP_ITEM
  | typeof CLOSE_HELP_ITEM
  | typeof PRIVACY_ITEM
  | typeof HELP_MENU_ITEM
  | typeof MESSAGE_ITEM
  | typeof TIPS_ITEM
  | typeof PRICING_ITEM

export const defaultItems: (isMobile: boolean) => Item[] = isMobile =>
  compact(
    CATALOG_ITEM,
    RELATIONSHIPS_ITEM,
    CAMPAIGNS_ITEM,
    GIFTS_ITEM,
    PRICING_ITEM,
    isMobile ? OPEN_HELP_ITEM : undefined,
  )

export const dashboardItems: (isMobile: boolean) => Item[] = isMobile =>
  compact(CATALOG_ITEM, isMobile ? OPEN_HELP_ITEM : undefined)

const helpItems: Item[] = compact(
  HELP_MENU_ITEM,
  PRIVACY_ITEM,
  MESSAGE_ITEM,
  TIPS_ITEM,
  CLOSE_HELP_ITEM,
)

type Button = {
  id: string
  icon: IconTypeOrIconString
  label: string
  onClick: () => void
  viewBox?: string
  hidden?: boolean
  badge?: string | number | null
  color?: string
  disabled?: boolean
}

type Dispatch = {
  openAccount: () => void
  openRelationshipManager: () => void
  openCampaigns: () => void
  openCatalog: () => void
  openGiftStore: () => void
  openPricing: () => void
}

const buttonsForItems: (
  tourId: string | undefined,
  dispatch: Dispatch,
  notificationCount: number | string | null,
  helpButtonAction: () => void,
  clickNavButton: (buttonName: string) => void,
) => { [key in Item]: Button } = (
  tourId,
  dispatch,
  notificationCount,
  helpButtonAction,
  clickNavButton,
) => ({
  RELATIONSHIPS_ITEM: {
    id: 'goto_contacts_btn',
    icon: 'CONTACT',
    label: 'Contacts',
    onClick: () => clickNavButton('goto_contacts_btn'),
  },

  CAMPAIGNS_ITEM: {
    id: 'goto_campaigns_btn',
    icon: 'CLIPBOARD',
    label: 'Campaigns',
    onClick: () => clickNavButton('goto_campaigns_btn'),
  },
  CATALOG_ITEM: {
    id: 'goto_catalog_btn',
    icon: 'SOCLOGO',
    label: 'Catalog',
    onClick: () => clickNavButton('goto_catalog_btn'),
  },

  GIFTS_ITEM: {
    id: 'goto_gift_store_btn',
    icon: 'TAG',
    label: 'Gifts',
    onClick: () => clickNavButton('goto_gift_store_btn'),
  },
  PRICING_ITEM: {
    id: 'goto_pricing_btn',
    icon: 'DOLLARSIGN',
    label: 'Pricing',
    onClick: () => clickNavButton('goto_pricing_btn'),
  },
  OPEN_HELP_ITEM: {
    id: 'open_help_items_btn',
    icon: 'QUESTIONMARK',
    label: 'Help',
    onClick: helpButtonAction,
  },
  CLOSE_HELP_ITEM: {
    id: 'close_help_items_btn',
    icon: 'CLOSE',
    label: 'Close',
    onClick: helpButtonAction,
  },
  PRIVACY_ITEM: {
    id: 'goto_privacy_btn',
    icon: 'COG',
    label: 'Privacy',
    onClick: () => {
      return window.open(
        'https://www.sendoutcards.com/resources/privacy-policy/',
        '_blank',
        'noreferrer',
      )
    },
  },
  HELP_MENU_ITEM: {
    id: 'goto_help_btn',
    icon: 'LIST',
    label: 'Help',
    onClick: () => {
      return window.open(
        'https://help.sendoutcards.com',
        '_blank',
        'noreferrer',
      )
    },
  },
  MESSAGE_ITEM: {
    id: 'goto_message_btn',
    icon: 'CHAT',
    label: 'Message',
    onClick: () => {
      if (window.Intercom) {
        window.Intercom('show')
      } else {
        console.log(
          'Intercom Does not exist on the window, please check your intercom config script',
        )
      }
    },
  },
  TIPS_ITEM: {
    id: 'goto_tips_btn',
    icon: 'INFO',
    label: 'Tips',
    onClick: () => window.Appcues.show(tourId),
  },
})

type ButtonComponentProps = {
  button: Button
  sidebarClass: string
}

const ButtonComponent: React.FC<ButtonComponentProps> = props => {
  const { button, sidebarClass } = props
  const iconSize = 22
  const iconColor = '#6f8394'

  return (
    <span
      id={button.id}
      className={`${styles.button} ${sidebarClass}`}
      onClick={button.disabled ? () => {} : () => button.onClick()}
    >
      <Icon
        icon={button.icon}
        size={iconSize}
        viewBox={button.viewBox}
        color={iconColor}
      />
      {button.badge && (
        <span className={styles.notificationBadge}>{button.badge}</span>
      )}
      <span
        className={styles.iconLabel}
        style={
          !button.disabled && button.color
            ? { color: button.color }
            : { color: '#484646' }
        }
      >
        {button.label}
      </span>
    </span>
  )
}

type Props = {
  items: Array<Item | Button> | ((isMobile: boolean) => Array<Item | Button>)
  rootCSS?: InterpolationWithTheme<unknown>
  isSidebarEnabled?: boolean
  drawerItems?: React.ReactNode
}

const UnconnectedBottomNav: React.FC<Props> = props => {
  const { children, items, isSidebarEnabled, rootCSS, drawerItems } = props

  const account = useAccount()
  const actions = useActions()
  const route = useRoute()
  const { tourId } = useSelector(state => state.helpmenu)
  const { isMobile } = useSelector(state => state.window)

  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showConfirmVisible, setShowConfirmVisible] = useState(false)
  const [buttonClicked, setButtonClicked] = useState('')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const performNavigation = (buttonId: string) => {
    switch (buttonId) {
      case 'goto_catalog_btn':
        actions.openCatalog()
        break
      case 'goto_campaigns_btn':
        actions.openCampaigns()
        break
      case 'goto_contacts_btn':
        actions.openRelationshipManager()
        break
      case 'goto_gift_store_btn':
        actions.openGiftStore()
        break
      case 'goto_pricing_btn':
        actions.openPricing()
        break
      case 'goto_account_btn':
        actions.openAccount()
        break
      case 'goto_notifications_btn':
        actions.openNotifications()
        break
      case 'goto_payment_settings_btn':
        actions.openPaymentSettings()
        break
      case 'goto_card_history_btn':
        actions.openCardHistory()
        break
      case 'goto_drafted_orders_btn':
        actions.openDraftedOrders()
        break
      case 'goto_drafted_cards_btn':
        actions.openDraftedCards()
        break
      case 'logout_btn':
        actions.logout()
        break
    }
  }

  const clickNavButton = (buttonId: string) => {
    const shouldShowDialog: boolean = route.subroute.path === OrdersRoute.path

    if (shouldShowDialog) {
      setButtonClicked(buttonId)
      setShowConfirmVisible(true)
      return
    }
    performNavigation(buttonId)
  }

  const sidebarClass = () => {
    return isSidebarEnabled ? styles.column : null
  }

  const { data: notificationCount } = useNotificationCount()
  const {
    data: pendingMembershipInvites,
  } = useReceivedPendingMembershipInvites()

  const notificationsCounter =
    (notificationCount ?? 0) + (pendingMembershipInvites?.length ?? 0)

  const notificationCountText =
    notificationsCounter > 0
      ? notificationsCounter <= 10
        ? notificationsCounter
        : '10+'
      : null

  const buttonsLookup = buttonsForItems(
    tourId,
    actions,
    notificationCountText,
    () => setIsHelpMenuOpen(!isHelpMenuOpen),
    clickNavButton,
  )

  const buttons = (isHelpMenuOpen
    ? helpItems
    : isFunction(items)
    ? items(isMobile)
    : items
  ).map(item => (typeof item === 'string' ? buttonsLookup[item] : item))

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div css={rootCSS}>
      <div
        className={`${styles.wrapper} ${sidebarClass()}`}
        style={{ paddingRight: '8px' }}
      >
        {children ? (
          <div
            className={`${styles.navRow} ${styles.children} ${sidebarClass()}`}
          >
            {children}
          </div>
        ) : (
          <div
            className={`${styles.navRow} ${sidebarClass()}`}
            style={{
              justifyContent: isMobile
                ? 'space-between'
                : isMobile
                ? 'space-around'
                : undefined,
            }}
          >
            <Box
              display="flex"
              p={1}
              alignItems="center"
              style={{ padding: 0 }}
            >
              <Box p={1} flexGrow={1}>
                <button
                  id="avatar_btn"
                  aria-controls="drop-down-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  style={{ border: 'none', background: 'none' }}
                >
                  <ProfileAvatar
                    account={account}
                    size={32}
                    style={{
                      zIndex: 300,
                      fontSize: '1.8rem',
                      color: '#fff',
                      letterSpacing: 1,
                    }}
                    isEditable={false}
                  />
                </button>
                <Menu
                  id="drop-down-menu"
                  anchorEl={anchorEl}
                  keepMounted={false}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    id="goto_account_btn"
                    onClick={() => actions.openAccount()}
                    style={{ fontSize: 'initial', padding: '3px 16px' }}
                  >
                    <Text type="body">{`Account #${account.genealogyId}`}</Text>
                  </MenuItem>
                  {!account.isNewUser && (
                    <MenuItem
                      onClick={() =>
                        (window.location.href = `${LEGACY_SITE_URL}/main-menu/?access_token=${account.token}`)
                      }
                      style={{ fontSize: 'initial', padding: '3px 16px' }}
                    >
                      <Text type="body">{'Legacy Site'}</Text>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() =>
                      (window.location.href = `${LEGACY_SITE_URL}/resources/`)
                    }
                    style={{ fontSize: 'initial', padding: '3px 16px' }}
                  >
                    <Text type="body">{'Resource Center'}</Text>
                  </MenuItem>
                  <MenuItem
                    id="logout_btn"
                    onClick={actions.logout}
                    style={{ fontSize: 'initial', padding: '3px 16px' }}
                  >
                    <Text type="body">{'Logout'}</Text>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
            {buttons
              .filter(button => !button.hidden)
              .map((button, index) => (
                <ButtonComponent
                  key={index}
                  button={button}
                  sidebarClass={sidebarClass()}
                />
              ))}
            {drawerItems && isMobile ? (
              <ButtonComponent
                button={{
                  id: '',
                  icon: isDrawerOpen ? 'CLOSE' : 'MENU',
                  label: isDrawerOpen ? 'Close' : 'Menu',
                  onClick: () => setIsDrawerOpen(!isDrawerOpen),
                }}
                sidebarClass={sidebarClass()}
              />
            ) : (
              <span
                id={'osano-cookie-button'}
                className={`${styles.button} ${sidebarClass()}`}
                onClick={() =>
                  window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
                }
              >
                <OsanoCookieSVG width="22" height="22" />
                <span className={styles.iconLabel} style={{ color: '#484646' }}>
                  Storage Preferences
                </span>
              </span>
            )}
          </div>
        )}
      </div>
      {showConfirmVisible && (
        <ConfirmDialog
          title="You are leaving the order checkout page."
          description="Your card is saved in your Account under Drafted Orders."
          primaryAction="decline"
          isOpen={showConfirmVisible}
          accept={{
            title: 'Continue and navigate away.',
            onClick: () => {
              performNavigation(buttonClicked)
              actions.clearOrder()
              setShowConfirmVisible(false)
            },
          }}
          decline={{
            title: 'Cancel and stay on this page.',
            onClick: () => setShowConfirmVisible(false),
          }}
          onClose={() => {
            setShowConfirmVisible(false)
          }}
        />
      )}
      <Drawer
        isOpen={isDrawerOpen}
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        location="left"
        openValue={300}
      >
        {drawerItems && drawerItems}
      </Drawer>
    </div>
  )
}

export const BottomNav = (props: Props) => (
  <SuspenseBoundary
    unresolved={<Transition />}
    failure={error => `Failed loading: ${error}`}
  >
    <UnconnectedBottomNav {...props} />
  </SuspenseBoundary>
)
