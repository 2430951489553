// import { Icon } from '@sendoutcards/editor/dist/components/Icons/Icon'
// import { SVGKeys } from '@sendoutcards/editor/dist/components/Icons/SVGs'
import React from 'react'
import { Flex, LoadingSpinner, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'
import { useState } from 'src/hooks'

type SendButtonProps = {
  onClick: () => void
  width?: string
  height?: string
  backgroundColor?: string
  title?: string
  isLoading?: boolean
}

export const SendButton: React.FC<SendButtonProps> = ({
  width,
  height = '50px',
  onClick,
  isLoading,
  title,
}) => {
  const icon: {
    name: IconType
    width: number
    height: number
    fill: string
  } = {
    name: 'send',
    width: title ? 20 : 32,
    height: title ? 20 : 32,
    fill: '#44A263',
  }
  const backgroundColor = isLoading ? '#fff' : '#EBFFEE'
  const [isSendHover, setIsSendHover] = useState(false)
  return (
    <div
      onMouseEnter={() => setIsSendHover(true)}
      onMouseLeave={() => setIsSendHover(false)}
    >
      <Flex
        onClick={onClick}
        width={width}
        height={height}
        minWidth="50px"
        cursor="pointer"
        justifyContent="center"
        alignItems="center"
        style={{
          marginLeft: 'auto',
          borderRadius: '16px',
          padding: '4px 6px',
          backgroundColor: isLoading ? '#fff' : backgroundColor,

          border: isSendHover ? '2px solid #FFF' : '2px solid #EBFFEE',
        }}
      >
        <Flex
          borderRadius={'16px'}
          inset={'4px'}
          backgroundColor={backgroundColor}
          alignItems="center"
          justifyContent="center"
        >
          {isLoading ? (
            <LoadingSpinner size={'small'} key="loading-spinner" />
          ) : (
            <Icon
              name={icon.name}
              width={icon.width}
              height={icon.height}
              fill={icon.fill}
            />
          )}
        </Flex>
        {title && (
          <Text
            type="body"
            weight="bold"
            outset={{ left: 'x_5', right: 'x_75' }}
            style={{
              color: '#44A263',
              whiteSpace: 'nowrap',
              transition: 'margin 0.3s ease',
            }}
            content={isLoading ? 'Loading ...' : title}
          />
        )}
      </Flex>
    </div>
  )
}
