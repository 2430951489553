import React, { FC } from 'react'
import { SVGProps } from './types'

export const Send: FC<SVGProps> = ({ width, height, fill, onClick }) => {
  const cursor = onClick ? 'pointer' : 'inherit'

  const style = {
    width,
    height,
    fill,
    cursor,
  }

  const svg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.8506 18.025C10.8673 18.025 9.47559 17.3333 8.37559 14.025L7.77555 12.225L5.97555 11.625C2.67555 10.525 1.98389 9.1333 1.98389 8.14994C1.98389 7.17494 2.67555 5.77494 5.97555 4.6666L13.0506 2.30827C14.8173 1.7166 16.2923 1.8916 17.2006 2.7916C18.1089 3.6916 18.2839 5.17494 17.6923 6.9416L15.3339 14.0166C14.2256 17.3333 12.8339 18.025 11.8506 18.025ZM6.36722 5.85827C4.05055 6.63327 3.22555 7.54994 3.22555 8.14994C3.22555 8.74996 4.05055 9.66663 6.36722 10.4333L8.46725 11.1333C8.65059 11.1916 8.80059 11.3416 8.85892 11.525L9.55892 13.625C10.3256 15.9416 11.2506 16.7666 11.8506 16.7666C12.4506 16.7666 13.3673 15.9416 14.1423 13.625L16.5006 6.54994C16.9256 5.2666 16.8506 4.2166 16.3089 3.67494C15.7673 3.13327 14.7173 3.0666 13.4423 3.4916L6.36722 5.85827Z"
        fill="#44A263"
      />
      <path
        d="M8.42567 11.9998C8.26732 11.9998 8.10898 11.9415 7.98398 11.8165C7.74232 11.5748 7.74232 11.1748 7.98398 10.9332L10.9673 7.9415C11.209 7.69984 11.609 7.69984 11.8507 7.9415C12.0923 8.18317 12.0923 8.58317 11.8507 8.82484L8.86733 11.8165C8.75067 11.9415 8.584 11.9998 8.42567 11.9998Z"
        fill="#44A263"
      />
    </svg>
  )
  return {
    ...svg,
    props: {
      ...svg.props,
      style,
    },
  }
}
