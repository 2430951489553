import {
  CardFragment,
  ColorFragment,
  LineFragment,
  Maybe,
  TextAlignment,
} from 'src/graphql/generated/graphql'
import { TextSelection } from 'src/editor/text/types'
import { OrderApiType } from 'src/orders/api'

/* Types & Interfaces */

export type OrderLine = {
  sendDelay?: LineFragment['sendDelay']
  card?: Maybe<
    Pick<CardFragment, 'id' | 'frontPreviewUrl' | 'isHorizontal'> & {
      cost?: CardFragment['cost']
    }
  >
}

export type Props = {
  cardId: string
  onAfterSave: () => Promise<void>
  onClose: () => void
  onBackToCatalog?: () => void
  context?: {
    headerText?: string
    headerSubText?: string
    lines?: OrderLine[]
    activeCardId?: string
    footerLabel: (card: CardFragment) => JSX.Element
  }
  saveButtonLabel: string
  saveButtonBackground?: string
  saveButtonColor?: string
  orderApi?: OrderApiType
  handleAddToOrder?: (cardId: string) => Promise<void>
  onReload?: () => Promise<void>
}

export interface TextFragment {
  fontId: string
  fontSize: number
  isReplacement?: boolean
  color: ColorFragment
  textAlign?: TextAlignment
  text?: string
  signatureFontId?: string
}

export enum Steps {
  Idle,
  EditPicture,
  EditText,
  EditBackgroundPicture,
  EditBackgroundColor,
  EditLayout,
}

interface StepBase {
  type: Steps
  fullBleedIndex: number
  panelIndex?: number
  elementIndex?: number
  textSelection?: TextSelection
}

interface IdleStep extends StepBase {
  type: Steps.Idle
}

interface EditPictureStep extends StepBase {
  type: Steps.EditPicture
  panelIndex: number
  elementIndex: number
  source: 'own' | 'social'
}

interface EditBackgroundPictureStep extends StepBase {
  type: Steps.EditBackgroundPicture
  source: 'own' | 'social'
}

interface EditTextStep extends StepBase {
  type: Steps.EditText
  panelIndex: number
  elementIndex: number
  textSelection: TextSelection
}

interface EditBackgroundColorStep extends StepBase {
  panelIndex: number
  type: Steps.EditBackgroundColor
}

interface EditLayoutStep extends StepBase {
  panelIndex: number
  type: Steps.EditLayout
}

export type Step =
  | IdleStep
  | EditBackgroundPictureStep
  | EditPictureStep
  | EditTextStep
  | EditBackgroundColorStep
  | EditLayoutStep
