export const useOffsetLimitPagination = (resultsPerPage = 10) => {
  const getOffset = (page: number) => {
    const newPage = Math.max(0, page - 1)
    return Math.round(newPage * resultsPerPage)
  }

  const getNextPageParam = <T extends { count: number; results: V }, V>(
    lastPage: T,
    allPages: T[],
  ) => {
    const results = allPages.flatMap(page => page.results)
    const fetchedCount = results.length

    if (fetchedCount >= lastPage.count) {
      return undefined
    }

    return fetchedCount / resultsPerPage + 1
  }

  return { getOffset, getNextPageParam, limit: resultsPerPage }
}
